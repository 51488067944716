
import { apiShopLists, apiShopSwitchStatus, apiShopFrozen, apiShopDel, apiChangeRemark, apiShopTypeLists, apiShopImport, apiShopSso, apiCashdeskSso } from '@/api/shop'
import { Component, Prop, Vue } from 'vue-property-decorator'
import lsPagination from '@/components/ls-pagination.vue'
import ExportData from '@/components/export-data/index.vue'
import ImportData from '@/components/import-data/index.vue'
import LsDialog from '@/components/ls-dialog.vue'
import { RequestPaging } from '@/utils/util'
@Component({
    components: {
        lsPagination,
        ExportData,
        ImportData,
        LsDialog
    }
})
export default class ShopList extends Vue {
    /** S Data **/

    apiShopLists = apiShopLists;
    apiShopImport = apiShopImport;

    pager: RequestPaging = new RequestPaging();

    cateLists: any = []

    searchObj = {
        status: '' as string | number,
        shop_info: '' as string | number,
        create_time: '' as string | number,
        shop_type_id: '' as string | number
    };
    /** E Data **/

    /** S Methods **/

    async getCateLists() {
        const { lists } = await apiShopTypeLists({ page_size: 100 })
        this.cateLists = lists
    }

    // 获取门店列表
    getShopLists(page?: number): void {
        page && (this.pager.page = page)
        this.pager.request({
            callback: apiShopLists,
            params: {
                ...this.searchObj
            }
        })
    }

    // 修改门店状态
    async shopSwitchStatusEdit(status: number, id: number): Promise<void> {
        await apiShopSwitchStatus({ id })
        this.getShopLists()
    }

    async changeRemarks(text: string, id: number): Promise<void> {
        await apiChangeRemark({ id: id, remark: text })
        this.getShopLists()
    }

    // 冻结门店
    async onShopFrozen(row: any): Promise<void> {
        await apiShopFrozen({ id: row.id, status: row.status == 1 ? 2 : 1 })
        this.getShopLists()
    }

    // 删除门店
    async onShopDelete(row: any): Promise<void> {
        await apiShopDel({ id: row.id })
        this.getShopLists()
    }

    // 重置搜索
    reset(): void {
        Object.keys(this.searchObj).map((key) => {
            this.$set(this.searchObj, key, '')
        })
        this.getShopLists(1)
    }

    // 去编辑门店
    toShopEdit(id: number | any): void {
        this.$router.push({
            path: '/shop/lists_detail',
            query: {
                id: id
            }
        })
    }

    // 修改超级管理员
    toSuperAdminUpdate(id: number | any): void {
        this.$router.push({
            path: '/shop/modify_account',
            query: {
                id: id
            }
        })
    }

    // 去商家后台
    async toShop(url: any, status: number, id: number) {
        if (status == 0) return this.$message.error('该门店暂未营业中')

        const { token, name } = await apiShopSso({ id: id })

        url = url + '/account/login?token=' + token + '&name=' + name
        if (url.indexOf('https://') === 0) window.open(url)
        else if (url.indexOf('http://') === 0) window.open(url)
        else window.open('http://' + url)
    }

    // 去收银台
    async toCashdesk(url: any, status: number, id: number) {
        if (status == 0) return this.$message.error('该门店暂未营业中')

        const { token, name } = await apiCashdeskSso({ id: id })

        url = url + '/account/login?token=' + token + '&name=' + name
        if (url.indexOf('https://') === 0) window.open(url)
        else if (url.indexOf('http://') === 0) window.open(url)
        else window.open('http://' + url)
    }
    

    /** E Methods **/

    /** S ods **/
    created() {
        this.getCateLists()
        this.getShopLists()
    }
}
