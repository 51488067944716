
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import LsDialog from '@/components/ls-dialog.vue'
@Component({
    components: {
        LsDialog
    }
})
export default class ExportData extends Vue {
    $refs!: { dialog: any }
    @Prop() method!: Function // 上传接口
    @Prop() modeApi!: string // 下载模版接口
    files: any = new Object()

    // 确认上传
    handleConfirm() {
        const loading = this.$loading({
            lock: true,
            text: '正在上传中...',
            spinner: 'el-icon-loading',
        })
        const form = new FormData()
        form.append('file', this.files)
        this.method(form).then(() => {
            this.$refs.dialog.close()
            this.$emit('refresh')
            this.files = new Object()
        }).finally(() => {
            loading.close()
        })
    }

    // 选择上传的文件
    choseUploadFile() {
        const file: HTMLElement | any = document.createElement('input');
        file.setAttribute('type', 'file')
        file.setAttribute('accept', '.xlsx,.xls')
        file.style.display = 'none';
        file.click()
        file.addEventListener('change', () => {
            this.files = file.files[0]
        })
    }
    
    // 下载模版
    downloadMode() {
        const link = document.createElement('a')
        link.href = this.modeApi
        link.setAttribute('download', '门店模版')
        link.style.display = 'none'
        document.body.appendChild(link)
        setTimeout(() => {
            link.click()
            document.body.removeChild(link)
        }, 66)
    }

    created() {

    }
}
